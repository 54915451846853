import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, TextField, Button, Box, MenuItem, FormControlLabel, Checkbox ,
  Dialog,
  DialogActions, DialogContent, DialogTitle, CardContent,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useNotifier } from "../../../Core/Notifier";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';

const EditExpenseForm = () => {
  const { id } = useParams(); // Get the expense ID from the URL
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [isHST, setIsHST] = useState(false);
  const [total, setTotal] = useState(0);
  const [hstAmount, setHstAmount] = useState(0);
  const { showErrorMessage, showMessage } = useNotifier();
  const [loading, setLoading] = useState(false); 
  const [expense, setExpense] = useState<any>(null); // State to store fetched expense data
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [category, setCategory] = useState('');
  const [remarks, setRemarks] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
  const [openModal, setOpenModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  

  interface Category {
    id: number;
    category_name: string;
    
  }

  const formik = useFormik({
    initialValues: {
      date: expense?.date || new Date().toISOString().split("T")[0],
      description: expense?.description || "",
      amount: expense?.amount || "",
      hst: expense?.hst || false,
      total: expense?.total || "",      
      category: expense?.category || "",
      status: expense?.status || "",
      file: expense?.files || [], 
      before_images: expense?.before_images || [], 
      after_images: expense?.after_images || [],
      remarks: expense?.remarks || "",
    },
    validationSchema: yup.object({    
      date: yup.date().required("Date is required"),
      description: yup.string().required("Description is required"),
      amount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be positive"),
      category: yup.string().required("Category is required"),
    }),
    onSubmit: async (values) => {
      //console.log("Formik Values on Submit:", values); 
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("date", values.date);
        formData.append("description", values.description);
        formData.append("amount", values.amount);
        formData.append("hst", isHST ? hstAmount.toString() : "0"); 
        formData.append("total", total.toString());
        formData.append("category", values.category);
        formData.append("status", values.status);
        formData.append("remarks", values.remarks || ""); // Ensure it's an empty string if not provided


        if (values.file && Array.isArray(values.file)) {
          values.file.forEach((file: File) => {
            formData.append("files[]", file);
          });
        }


        if (values.before_images && Array.isArray(values.before_images)) {
          values.before_images.forEach((before_images: File) => {
            formData.append("before_images[]", before_images); // Append each file individually
          });
        }
        
        
        if (values.after_images && Array.isArray(values.after_images)) {
          values.after_images.forEach((after_images: File) => {
            formData.append("after_images[]", after_images); // Append each file individually
          });
        }
        
       
        const response = await axios.post(`${API_URL}/expense/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        showMessage("Expense updated successfully!");
        navigate(`/expense/list`); // Redirect after update
      } catch (error) {
        showErrorMessage("An error occurred while updating the expense.");
      } finally {
        setLoading(false);
      }
    },
  });

 
  

  useEffect(() => {
    const fetchExpenseAndCategories = async () => {
      setLoading(true);
      try {
        // Fetch categories first
        const categoriesResponse = await axios.get(`${API_URL}/view-expense-category`);
        const fetchedCategories = categoriesResponse.data; // Assuming categories are an array
        setCategories(fetchedCategories);
  
        // Fetch expense details
        const expenseResponse = await axios.get(`${API_URL}/expense/${id}`);
        const fetchedExpense = expenseResponse.data;
  
        // Set expense state
        console.log(fetchedExpense);
        setExpense(fetchedExpense);
        setDescription(fetchedExpense.description);
        setAmount(fetchedExpense.amount);
        setTotal(fetchedExpense.total);
        setIsHST(fetchedExpense.hst > 0);
        setHstAmount(fetchedExpense.hst);
        setCategory(fetchedExpense.category);
        setRemarks(fetchedExpense.remarks);
  
        const parsedFiles = fetchedExpense.file ? JSON.parse(fetchedExpense.file) : [];
        const parsedBeforeFiles = fetchedExpense.before_images ? JSON.parse(fetchedExpense.before_images) : [];
        const parsedAfterFiles = fetchedExpense.after_images ? JSON.parse(fetchedExpense.after_images) : [];
        // Set formik values with category from fetched expense
        formik.setValues({
          date: fetchedExpense.date,
          description: fetchedExpense.description,
          amount: fetchedExpense.amount,
          hst: fetchedExpense.hst > 0,
          total: fetchedExpense.total,
          category: fetchedExpense.category, // Ensure this matches the `id` from categories
          status: fetchedExpense.status, 
          file: Array.isArray(parsedFiles) ? parsedFiles : [],
          before_images: Array.isArray(parsedBeforeFiles) ? parsedBeforeFiles : [],
          after_images: Array.isArray(parsedAfterFiles) ? parsedAfterFiles : [],
          remarks: fetchedExpense.remarks,
        });


      } catch (error) {
        showErrorMessage("Failed to fetch expense or categories data.");
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchExpenseAndCategories();
    }
  }, [id]);
  

 

 const handleAmountChange = (
     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
     const inputValue = event.target.value; // Raw input value
     const amount = inputValue ? parseFloat(inputValue) : 0; // Default to 0 if empty
   
     formik.setFieldValue("amount", inputValue); // Update formik directly with raw value for better UX
   
     if (isHST) {
       const hstAmount = amount * 0.13; // Calculate HST
       const updatedTotal = amount + hstAmount; // Calculate total
       setHstAmount(hstAmount);
       setTotal(parseFloat(updatedTotal.toFixed(2)));
       formik.setFieldValue("total", updatedTotal.toFixed(2)); // Update total
     } else {
       setHstAmount(0); // Reset HST
       setTotal(amount); // Total equals amount
       formik.setFieldValue("total", amount.toFixed(2)); // Update total
     }
   };
   
   const handleHSTToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
     const checked = event.target.checked;
     setIsHST(checked);
   
     // Parse the current values from Formik (default to 0 if empty)
     const currentAmount = parseFloat(formik.values.amount || "0");
     const currentTotal = parseFloat(formik.values.total || "0");
   
     if (checked) {
       // HST is checked
       if (currentTotal > 0 && currentAmount === 0) {
         // Case 2: User entered total (which includes HST), calculate base amount and HST
         const baseAmount = parseFloat((currentTotal / 1.13).toFixed(2)); // Calculate and round base amount
         const calculatedHST = parseFloat((currentTotal - baseAmount).toFixed(2)); // Calculate and r
  
         setHstAmount(calculatedHST); // Set the calculated HST
         setTotal(parseFloat(currentTotal.toFixed(2)));// Keep total as entered
         console.log(parseFloat(currentTotal.toFixed(2)))
         formik.setFieldValue("amount", baseAmount.toFixed(2)); // Update the amount in the form
       } else if (currentAmount > 0) {
      
         // Case 1: User entered amount, calculate HST and total from amount
         const calculatedHST = parseFloat((currentAmount * 0.13).toFixed(2)); // Calculate and round HST
         const updatedTotal = parseFloat((currentAmount + calculatedHST).toFixed(2)); // Calculate and round total
   
         setHstAmount(calculatedHST);
         setTotal(updatedTotal);
      
         formik.setFieldValue("total", updatedTotal.toFixed(2)); // Update total based on amount
       }
     } else {
       // HST is unchecked
       if (currentTotal > 0) {
         // If total is entered, recalculate the base amount from total (remove HST)
         const baseAmount = currentTotal / 1.13; // Calculate the base amount before HST
   
         setHstAmount(0); // Reset HST to 0
         const roundedBaseAmount = parseFloat(baseAmount.toFixed(2)); // Round base amount to 2 decimal places
         setTotal(roundedBaseAmount); // Set total back to the base amount (without HST)
      
         formik.setFieldValue("total", roundedBaseAmount.toFixed(2)); // Update total in the form
         formik.setFieldValue("amount", roundedBaseAmount.toFixed(2)); // Ensure amount matches the recalculated base amount
       } else if (currentAmount > 0) {
         // If only amount is entered, reset HST to 0 and set total to the amount
         setHstAmount(0); // Reset HST to 0
         setTotal(currentAmount); // Set total to amount
         formik.setFieldValue("total", currentAmount.toFixed(2)); // Set total in the form
       
       }
     }
   };
   
   
   
   
   const handleTotalChange = (
     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
     const inputValue = event.target.value; // Raw input value
     const total = inputValue ? parseFloat(inputValue) : 0; // Default to 0 if empty
   
     formik.setFieldValue("total", inputValue); // Update Formik directly with raw value
   
     if (isHST) {
       // When HST is checked, calculate base amount and HST
       const baseAmount = total / 1.13; // Calculate the base amount before HST
       const hstAmount = total - baseAmount; // Calculate HST
       setHstAmount(hstAmount);
       setTotal(total); // Keep total as entered
       formik.setFieldValue("amount", baseAmount.toFixed(2)); // Update amount
     } else {
       // When HST is unchecked, total equals the amount (no HST)
       setHstAmount(0); // Reset HST
       setTotal(total); // Total equals input total
       //formik.setFieldValue("amount", total.toFixed(2)); // Update amount
       formik.setFieldValue("amount", 0); // Update amount
 
     }
   };

   
  const handleOpenModal = (file: string): void => {
    //const url = `${LARAVEL_API_URL}/${file}`;
    setFileUrl(file);
    setOpenModal(true);
   
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFileUrl(null);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card>
            <Grid container justifyContent="space-between" sx={{ borderBottom: 2, padding: 2 }}>
           <Grid item xs={12} pt={2} alignContent={"center"}>
                <Typography className={classes.cardTitle}>Edit Expense</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

          <Grid item xs={12} md={9}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    {/* Date Field */}
                   <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Date</Typography>
                      <TextField
                        type="date"
                        size="small"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        name="date"
                        fullWidth
                      />
                    </Grid>

                    {/* Description Field */}
                    <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Description</Typography>
                      <TextField
                        placeholder="Enter description"
                        size="small"
                        {...formik.getFieldProps("description")}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && typeof formik.errors.description === "string" ? formik.errors.description : ""}
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Grid>

                    {/* Amount Field */}
                  <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Amount</Typography>
                      <TextField
                        placeholder="Enter amount"
                        size="small"
                        type="number"
                        value={formik.values.amount}
                        onChange={(event) => handleAmountChange(event)}
                        fullWidth
                      />
                    </Grid>

                    {/* HST Checkbox */}
                    <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Include HST(13%)?</Typography>
                      <Grid container alignItems="center">
                        <Grid item>
                          <FormControlLabel
                            control={<Checkbox checked={isHST} onChange={handleHSTToggle} color="primary" />}
                            label="Yes"
                          />
                        </Grid>
                       {isHST && (
                            <Grid item sx={{ marginLeft: 2 }}>
                              <Typography variant="body2">
                                HST = ${Number(hstAmount).toFixed(2)}
                              </Typography>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>

                    {/* Total Field */}
                     <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Total</Typography>
                      <TextField
                        size="small"
                        type="number"
                        value={total}
                        onChange={(event) => handleTotalChange(event)} 
                        fullWidth
                      />
                    </Grid>

                    {/* Category Dropdown */}
                     <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Category</Typography>
                      <TextField
                        select
                        size="small"
                        {...formik.getFieldProps("category")}
                        value={formik.values.category || ""} 
                        fullWidth
                      >
                        {categories.map((category) => (
    <MenuItem key={category.id} value={category.id}>
      {category.category_name}
    </MenuItem>
  ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                       <Typography variant="subtitle1">Status</Typography>
                       <FormControlLabel
                         control={
                           <Checkbox
                             checked={formik.values.status === "Paid"} // Check if status is "Paid"
                             onChange={(e) => formik.setFieldValue("status", e.target.checked ? "Paid" : "Unpaid")} // Update Formik value based on checkbox state
                             color="primary"
                           />
                         }
                         label="Paid"
                       />
                     </Grid>

                     <Grid container spacing={2} mt={2} ml={2} mb={2}>
  <Grid item xs={12} sm={4}>
    <Typography variant="subtitle1">Upload Invoice</Typography>
    <input
      type="file"
      multiple
      onChange={(event) => {
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
        const allFiles = [
          ...(formik.values.file ?? []), // Use an empty array if file is null
          ...newFiles,
        ];
        formik.setFieldValue("file", allFiles); // Update Formik's file field
      }}
      style={{ display: "none" }}
      id="file-upload"
    />
    <Box>
      <label htmlFor="file-upload">
        <Button variant="outlined" component="span">Choose File</Button>
      </label>

    
      {formik.values.file && formik.values.file.length > 0 && (
  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
    Selected Files:
    <ul>
      {formik.values.file.map((file: File, index: number) => { // Explicitly type `file` as `File`
        // Check if the file is a File object or a URL
        const fileUrl = file instanceof File 
          ? URL.createObjectURL(file) 
          : `${LARAVEL_API_URL}/${file}`; // Use existing URL for old files

        return (
          <li key={index} style={{ display: 'inline-block'}}>
            {/* View Button */}
            <Button
              variant="text"
              size="small"
              onClick={() => handleOpenModal(fileUrl)}  
              sx={{ minWidth: "auto", padding: 0, marginRight: 1 }}
            >
              <DescriptionIcon style={{ fontSize: 16 }} />
            </Button>
            <span>{`Invoice ${index + 1}`}</span>

            {/* Remove Button */}
            <Button
  size="small"
  color="secondary"
  onClick={() => {
    // Filter the array to remove the file at the current index
    const updatedFiles = formik.values.file.filter((file: File, i: number) => i !== index);
    // Update the Formik field with the new array
    formik.setFieldValue("file", updatedFiles);
  }}
>
  <CancelIcon sx={{ color: 'red', backgroundColor: '#f8d7da', borderRadius: '50%' }} />
</Button>

          </li>
        );
      })}
    </ul>
  </Typography>
)}


        
    </Box>
  </Grid>


<Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
                <DialogTitle>View File</DialogTitle>
                <DialogContent>
                {fileUrl?.endsWith('.pdf') ? (
          <iframe
            src={fileUrl || ''}
            style={{
              width: '100%',
              height: '500px',
            }}
            title="PDF Viewer"
          />
        ) : (
          <img
            src={fileUrl || ''}
            alt="File Content"
            style={{
              width: '100%',
              height: '500px',
              objectFit: 'contain', // Ensures the image fits within the available space
            }}
          />
        )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal} color="primary">Close</Button>
                </DialogActions>
              </Dialog>

                <Grid item xs={12} sm={4}>
    <Typography variant="subtitle1">Before Images</Typography>
    <input
      type="file"
      multiple
      onChange={(event) => {
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
        const allFiles = [
          ...(formik.values.before_images ?? []), // Use an empty array if file is null
          ...newFiles,
        ];
        formik.setFieldValue("before_images", allFiles); // Update Formik's file field
      }}
      style={{ display: "none" }}
  id="before-image-upload"
    />
    <Box>
      <label htmlFor="before-image-upload">
        <Button variant="outlined" component="span">Choose File</Button>
      </label>

    
      {formik.values.before_images && formik.values.before_images.length > 0 && (
  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
    Selected Files:
    <ul>
      {formik.values.before_images.map((before_images: File, index: number) => { // Explicitly type `file` as `File`
        // Check if the file is a File object or a URL
        const fileUrl = before_images instanceof File 
          ? URL.createObjectURL(before_images) 
          : `${LARAVEL_API_URL}/${before_images}`; // Use existing URL for old files

        return (
          <li key={index} style={{ display: 'inline-block' }}>
            {/* View Button */}
            <Button
              variant="text"
              size="small"
              onClick={() => handleOpenModal(fileUrl)}  
              sx={{ minWidth: "auto", padding: 0, marginRight: 1 }}
            >
              <DescriptionIcon style={{ fontSize: 16 }} />
            </Button>
            <span>{`BeforeImages ${index + 1}`}</span>

            {/* Remove Button */}
            <Button
  size="small"
  color="secondary"
  onClick={() => {
    // Filter the array to remove the file at the current index
    const updatedFiles = formik.values.before_images.filter((file: File, i: number) => i !== index);
    // Update the Formik field with the new array
    formik.setFieldValue("before_images", updatedFiles);
  }}
>
  <CancelIcon sx={{ color: 'red', backgroundColor: '#f8d7da', borderRadius: '50%' }} />
</Button>

          </li>
        );
      })}
    </ul>
  </Typography>
)}


        
    </Box>
  </Grid>
    <Grid item xs={12} sm={4}>
    <Typography variant="subtitle1">After Images</Typography>
    <input
      type="file"
      multiple
      onChange={(event) => {
        const newFiles = event.currentTarget.files ? Array.from(event.currentTarget.files) : [];
        const allFiles = [
          ...(formik.values.after_images ?? []), // Use an empty array if file is null
          ...newFiles,
        ];
        formik.setFieldValue("after_images", allFiles); // Update Formik's file field
      }}
      style={{ display: "none" }}
      id="after-image-upload"
    />
    <Box>
      <label htmlFor="after-image-upload">
        <Button variant="outlined" component="span">Choose File</Button>
      </label>

    
      {formik.values.after_images && formik.values.after_images.length > 0 && (
  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
    Selected Files:
    <ul>
      {formik.values.after_images.map((after_images: File, index: number) => { // Explicitly type `file` as `File`
        // Check if the file is a File object or a URL
        const fileUrl = after_images instanceof File 
          ? URL.createObjectURL(after_images) 
          : `${LARAVEL_API_URL}/${after_images}`; // Use existing URL for old files

        return (
          <li key={index} style={{ display: 'inline-block' }}>
            {/* View Button */}
            <Button
              variant="text"
              size="small"
              onClick={() => handleOpenModal(fileUrl)}  
              sx={{ minWidth: "auto", padding: 0, marginRight: 1 }}
            >
              <DescriptionIcon style={{ fontSize: 16 }} />
            </Button>
            <span>{`AfterImage ${index + 1}`}</span>

            {/* Remove Button */}
            <Button
  size="small"
  color="secondary"
  onClick={() => {
    // Filter the array to remove the file at the current index
    const updatedFiles = formik.values.after_images.filter((file: File, i: number) => i !== index);
    // Update the Formik field with the new array
    formik.setFieldValue("after_images", updatedFiles);
  }}
>
  <CancelIcon sx={{ color: 'red', backgroundColor: '#f8d7da', borderRadius: '50%' }} />
</Button>

          </li>
        );
      })}
    </ul>
  </Typography>
)}


        
    </Box>
  </Grid>
</Grid>

<Grid item xs={12} sm={7}>
              <Typography variant="subtitle1">Remarks</Typography>
              <TextField
                placeholder="Enter remarks"
                size="small"
                {...formik.getFieldProps("remarks")}
                value={formik.values.remarks || ""}
                multiline
                rows={4} 
                fullWidth
                
              />
            </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Submit and Cancel Buttons */}
            <Grid item xs={12} pt={3}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                     <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        className={classes.addButton}
                        startIcon={<CloseIcon />}
                        // onClick={() => formik.resetForm()}
                        onClick={() =>navigate('/expense/list')}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>
                      <Button
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<SaveAltIcon />}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditExpenseForm;
