import React, { useEffect, useMemo, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Card,
  Dialog,
  DialogActions, DialogContent, DialogTitle, CardContent
} from "@mui/material";
import { FaMoneyBillWave, FaPlusCircle ,FaFileAlt, FaClipboardList , FaChartPie , FaCog , FaMoneyCheckAlt, FaChartBar } from 'react-icons/fa'; // Example icons

import { useNotifier } from "../../../Core/Notifier";
import { useStyles } from "../../../theme/appStyles";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Pagination from "../../../Components/Pagination";
import axios from "axios";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import { Tooltip as MuiTooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';  
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import LoopIcon from '@mui/icons-material/Loop';           // Circular arrow, used for Before
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'; // Outlined circular arrow, used for After
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


ChartJS.register(ArcElement, Tooltip, Legend);

interface Expense {
  id: number;
  date: string;
  description: string;
  remarks: string;
  amount: number;
  hst: number;
  category: number;
  status: number;
  total: number;
  file?: string;
  created_at: string;
  before_images?: string;
  after_images?: string;
}

interface Category {
  id: number;
  category_name: string;
}

interface Driver {
  id: number;
  driverFirstName: string;
  driverLastName: string;
  driverUserRole: string;
}

interface Deposit {
  id: number;
  amount: string;
  user_id: number;
  created_at: string;
  date: string;
  is_approved: boolean; 
  remarks: string;
}

interface UserInfo {
  id: number;
  username: string;
  token: string;
  user_id: number;
}


const Index = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState<Expense[]>([]); 
  const [categories, setCategories] = useState<Category[]>([]); 
  const [team, setTeam] = useState<Driver[]>([]); 
  const [deposit, setDeposit] = useState<Deposit[]>([]); 
  const { showErrorMessage, showMessage } = useNotifier();
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchQuery, setSearchQuery] = useState(""); 
  const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(50);
const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
const [openModal, setOpenModal] = useState(false);
const [openModalView, setOpenModalView] = useState(false);
const [fileUrl, setFileUrl] = useState<string | null>(null);
const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
const [tooltipOpen, setTooltipOpen] = React.useState<Record<number, boolean>>({});
const [showImages, setShowImages] = useState(false);
const [beforeImages, setBeforeImages] = useState<string[]>([]);
const [afterImages, setAfterImages] = useState<string[]>([]);
const isPdf = fileUrl?.endsWith('.pdf');


const handleViewImages = (beforeImagesData: string[], afterImagesData: string[]) => {
  console.log("Before Images Data:", beforeImagesData);  // This should now show the images
  setBeforeImages(beforeImagesData || []);
  setAfterImages(afterImagesData || []);
  setOpenModalView(true);
};


const handleTooltipOpen = (id: number) => {
  if (window.innerWidth > 768) {
    setTooltipOpen((prev) => ({ ...prev, [id]: true })); // Open tooltip on hover for desktop
  }
};

const handleTooltipClose = (id: number) => {
  if (window.innerWidth > 768) {
    setTooltipOpen((prev) => ({ ...prev, [id]: false })); // Close tooltip on hover leave for desktop
  }
};

// Handle touch on mobile
const handleMobileTooltipToggle = (id: number) => {
  if (window.innerWidth <= 768) {
    setTooltipOpen((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the open state for the specific tooltip
    })); // Toggle open/close on tap for mobile
  }
};

const categoryMap = useMemo(
  () =>
    categories.reduce((map, category) => {
      map[category.id] = category.category_name;
      return map;
    }, {} as Record<number, string>),
  [categories]
);

  const filteredExpenses = expenses.filter(
    (expense) =>
      expense.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (categoryMap[expense.category] || "")
    .toLowerCase()
    .includes(searchQuery.toLowerCase())
);

const totalHst = filteredExpenses.reduce((sum, expense) =>  {
  return sum + (Number(expense.hst) || 0);
}, 0);

const totalAmount = filteredExpenses.reduce((sum, expense) => {
  return sum + (Number(expense.amount) || 0);
}, 0);

const totalAmountWithTax = filteredExpenses.reduce((sum, expense) => {
  return sum + (Number(expense.total) || 0);
}, 0);



  const handleEditClick = (id: number) => {
    navigate(`/expense/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    // Delete expense logic
    axios.delete(`${API_URL}/delete-expense/${id}`).then(() => {
      setExpenses(expenses.filter((expense) => expense.id !== id));
      showMessage("Deleted successfully");
    }).catch((error) => {
     // console.error("Failed to delete expense", error);
      showErrorMessage("Failed to delete the template");
    });
  };

  

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-expenses`);
        setExpenses(response.data); // Set the fetched data to state
    
        
      } catch (error) {
        console.error("Failed to load expenses", error);
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-expense-category`);
        setCategories(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error("Failed to load categories", error);
      }
    };
    const fetchTeam = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-team`);
        // setTeam(response.data.data);
        const team: Driver[] = response.data.data;
        const managers = team.filter(driver => driver.driverUserRole === 'manager');
        setTeam(managers); 
       // console.log(response.data.data);
      } catch (error) {
        console.error("Failed to load team members", error);
      }
    };

    const fetchDeposit = async () => {
      try {
        const response = await axios.get(`${API_URL}/view-deposit`);
        setDeposit(response.data); // Set the fetched data to state
        // console.log(response.data);
      } catch (error) {
        console.error("Failed to load expenses", error);
      }
    };

    const fetchUserInfo = async () => {
      try {
          const response = await axios.post(
              `${API_URL}/user/userInfo`,
              {}, // No body required
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem('token') || ''}`, // Include token from localStorage
                  },
              }
          );
          setUserInfo(response.data.data); // Update your state with the response
        console.log(response.data.data)
      } catch (error) {
          console.error('Error fetching user info', error);
      }
  };

    fetchExpenses();
    fetchCategories();
    fetchTeam();
    fetchDeposit();
    fetchUserInfo();
  }, [API_URL]);

 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  
  const generateYearlyData = (expenses: Expense[]) => {
    const yearlyData = Array(12).fill(0); // Array to store expenses for each month (12 months)
  
    expenses.forEach((expense) => {
      const expenseDate = new Date(expense.date);
      const month = expenseDate.getMonth(); // Get month (0-11)
      
      // Ensure expense.total is a number
      let totalExpense = expense.total;
      
      if (typeof totalExpense === 'string') {
        totalExpense = parseFloat(totalExpense); // Convert string to number
      }
    
      // Check if totalExpense is a valid number
      if (!isNaN(totalExpense)) {
        yearlyData[month] += totalExpense; // Aggregate expense by month
      } else {
        console.error("Invalid total expense:", expense.total); // Log invalid total
      }
    });
  
    // Ensure that no NaN values are in yearlyData
    const validYearlyData = yearlyData.map((value) => (isNaN(value) ? 0 : value)); // Replace NaN with 0
  
    //console.log(validYearlyData); // Check the data
    return validYearlyData;
  };
  
  
  const generateCategoryData = (expenses: Expense[], categories: Category[]) => {
    const categoryTotals: Record<string, number> = {};

    // Map category ID to category name
    const categoryMap = categories.reduce((acc, category) => {
      acc[category.id] = category.category_name;
      return acc;
    }, {} as Record<number, string>);

    expenses.forEach((expense) => {
      let totalExpense = expense.total;

      // Ensure totalExpense is a number
      if (typeof totalExpense === 'string') {
        totalExpense = parseFloat(totalExpense); // Convert string to number
      }

      // Get the category name using the category ID
      const categoryName = categoryMap[expense.category];

      if (categoryName) {
        //console.log(`Processing Category: ${categoryName}, Total Expense: ${totalExpense}`);

        // Check if totalExpense is a valid number
        if (!isNaN(totalExpense)) {
          if (!categoryTotals[categoryName]) {
            categoryTotals[categoryName] = 0;
          }
          categoryTotals[categoryName] += totalExpense; // Aggregate by category name
        } else {
          console.error("Invalid category total:", expense.total);
        }
      } else {
        console.error("Invalid category ID:", expense.category);
      }
    });

    // Ensure that no NaN values are in categoryTotals
    const validCategoryTotals = Object.fromEntries(
      Object.entries(categoryTotals).map(([key, value]) => [key, isNaN(value) ? 0 : value])
    );

    //console.log('Final Category Totals:', validCategoryTotals);
    return validCategoryTotals;
  };
  
  
  
  
  const categoryData = generateCategoryData(expenses, categories);
  const yearlyData = generateYearlyData(expenses);
  

  const handleAddProductClick = () => {
    navigate("/expense/create");
  };

  const handleAddCategoryClick = () => {
    navigate("/expense-category/list");
  };

  const chartData = [
    { name: "Total HST", y: totalHst },
    { name: "Total Amount (Without hst)", y: totalAmount },
    // { name: "Total Amount (with hst)", y: totalAmountWithTax }
  ];
  
  // Highcharts options
  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 350,
      width: 250,
    },
    title: {
      text: " ",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        innerSize: "40%",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      symbolRadius: 0,
      itemStyle: {
        fontWeight: "normal",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Expenses',
        colorByPoint: true,
        data: chartData, // Data for the three totals
      },
    ],
  };

  const parseFiles = (file: string | undefined): string[] => {
    try {
      return file ? JSON.parse(file) : [];
    } catch (error) {
      return [];
    }
  };
  // Function to handle opening the modal
  const handleOpenModal = (file: string): void => {
    const url = `${LARAVEL_API_URL}/${file}`;
    setFileUrl(url);
    setOpenModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setFileUrl(null);
  };

  const handleCloseModalView = () => {
    setOpenModalView(false);
   // setFileUrl(null);
  };

  const handleDepositClick = (id: any) => {
    // console.log(`${id} Deposit Clicked`);
   // navigate("/deposit/create");
    navigate("/deposit/create", { state: { userId: id } });
  };


  const lineChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      // width: 500,
    },
    title: {
      text: 'Yearly Expense Trend',
    },
    xAxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    yAxis: {
      title: {
        text: 'Expense Amount ($)',
      },
    },
    credits: {
      enabled: false, // Disable the watermark
    },
    series: [
      {
        name: 'Total Expense',
        data: yearlyData,
      },
    ],
    
  };
  
  // Bar Chart (Expense Category)
  const barChartOptions = {
    chart: {
      type: 'column',
      height: 350,
      // width: 500,
    },
    title: {
      text: 'Expense by Category',
    },
    xAxis: {
      categories: categories.map((category) => category.category_name), // Category names
    },
    yAxis: {
      title: {
        text: 'Expense Amount ($)',
      },
    },
    credits: {
      enabled: false, // Disable the watermark
    },
    series: [
      {
        name: 'Expense',
        data: categories.map((category) => categoryData[category.category_name] || 0), // Ensure the data is mapped correctly to the categories
      },
    ],
  };


 const calculateCategoryHST = (expenses: Expense[], categories: Category[]) => {
  const categoryHST: Record<string, number> = {};

  const categoryMap = categories.reduce((acc, category) => {
    acc[category.id] = category.category_name;
    return acc;
  }, {} as Record<number, string>);

  expenses.forEach((expense) => {
    const hst = Number(expense.hst) || 0; // Use the existing value or default to 0
    const categoryName = categoryMap[expense.category];

    if (categoryName) {
      categoryHST[categoryName] = (categoryHST[categoryName] || 0) + hst;
    }
  });

  return categoryHST;
};

// Example Usage
const categoryHST = calculateCategoryHST(expenses, categories);
const totalBankBalance = deposit.filter((d) => d.is_approved).reduce((acc, curr) => acc + Number(curr.amount), 0);
const overallExpense = expenses.reduce((acc, expense) => acc + Number(expense.total), 0);
const paidExpense =  expenses
.filter(expense => String(expense.status) === 'Paid')  // Convert status to string
.reduce((total, expense) => total + expense.amount, 0); 


const calculatePieData = (categoryName: string) => {
  const totalExpense = categoryData[categoryName] || 0;
  const totalHST = categoryHST[categoryName] || 0;
  const overallExpensePercentage = ((totalExpense / overallExpense) * 100).toFixed(2);

  return {
    labels: [`${categoryName} %`, `Overall Expense`],
    datasets: [
      {
        data: [totalExpense, overallExpense], // Data for percentage and total expense
        backgroundColor: ['#4CAF50', '#FF9800'], // Green for percentage, Orange for overall expense
        hoverOffset: 4,
      },
    ],
  };
};


const handleApproveDeposit = (depositId: number) => {
  // API call to approve the deposit (you can use Axios or Fetch)
  axios
    .put(`${API_URL}/deposits/${depositId}/approve`,   { approved_by: userInfo?.user_id } )
    .then((response) => {
      showMessage("Approved successfully");
      // Update the state to reflect the change (this will re-render the UI)
      setDeposit((prevDeposits) =>
        prevDeposits.map((deposit) =>
          deposit.id === depositId ? { ...deposit, is_approved: true } : deposit
        )
      );
    })
    .catch((error) => {
      console.error('Error approving deposit:', error);
    });
};

const calculatePaidExpenses = () => {
  return expenses
    .filter(expense => String(expense.status) === 'Paid') // Ensure status is a string and matches "Paid"
    .reduce((total, expense) => {
      // Check if the amount is a string and parse it to a number if necessary
      const expenseTotal = typeof expense.total === 'string' ? parseFloat(expense.total) : expense.total;
      return total + (isNaN(expenseTotal) ? 0 : expenseTotal);
    }, 0);
};


const formatCurrency = (amount: number) => {
  return amount.toFixed(2); // Format the number to two decimal places
};

  return (

    
    <>
 
    
    
    <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 1 }}>
      {/* Card 1 */}
      <Grid item xs={12} sm={4} md={4}>
        <Card elevation={3} onClick={handleAddProductClick}
        >
          <CardContent>
            <Grid container direction="column" alignItems="center">
              <FaClipboardList   size={40} color="#4CAF50" />
              <Typography variant="h5" mt={2}>Add Expense</Typography>
              {/* <Typography variant="body2" color="textSecondary">Manage your expenses easily.</Typography> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Card 2 */}
      <Grid item xs={12} sm={4} md={4}>
        <Card elevation={3}   onClick={handleAddCategoryClick}>
          <CardContent>
            <Grid container direction="column" alignItems="center">
            <FaMoneyCheckAlt size={40} color="#3f51b5"/>
              <Typography variant="h5" mt={2}>Manage Expense</Typography>
             
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Card 3 */}
      <Grid item xs={12} sm={4} md={4}>
        <Card elevation={3}>
          <CardContent>
            <Grid container direction="column" alignItems="center">
            <FaFileAlt size={40} color="#b100c3" />
              <Typography variant="h5" mt={2}>Report</Typography>
            
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>


    <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 1 }}>
  {team .filter((person) => person.id !== userInfo?.user_id) // Exclude the logged-in user's data
    .map((person, index) => {
    const personDeposits = deposit.filter((d) => d.user_id === person.id); // Filter all deposits for the person

    const personTotalDeposit = personDeposits
      .filter((d) => d.is_approved) // Only include approved deposits for the total calculation
      .reduce((acc, curr) => acc + Number(curr.amount), 0);

    const unapprovedDeposits = personDeposits.filter((d) => !d.is_approved); // Filter out unapproved deposits

    // Only render the Card if there are unapproved deposits for this person
    if (unapprovedDeposits.length === 0) return null;

    return (
      <Grid item xs={12} sm={6} md={6} key={index}>
        <Card elevation={3} sx={{ height: '350px', display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: 1 }}>
            <Grid container alignItems="center" mt={1} justifyContent="center" spacing={2}>
              <Grid item>
                <Typography variant="body2" align="center" sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                  {person.driverFirstName} {person.driverLastName}
                </Typography>
              </Grid>
            </Grid>

            {/* Section to approve deposits */}
            {unapprovedDeposits.length > 0 && (
              <Box mt={3}>
                <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
                  Pending Approvals
                </Typography>
                <Box sx={{ maxHeight: '200px', overflowY: 'auto' }} className={classes.thinScrollbar}>
                  <Table className={classes.table}>
                    <TableHead className={classes.tableHeaderRow}>
                      <TableRow>
                        <TableCell className={classes.tablheader}>Date</TableCell>
                        <TableCell className={classes.tablheader}>Amount</TableCell>
                        <TableCell className={classes.tablheader}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unapprovedDeposits.map((depositItem, idx) => (
                        <TableRow key={idx}>
                          <TableCell scope="row" className={classes.tablCell}>
                            {new Date(depositItem.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell scope="row" className={classes.tablCell}>
                            ${depositItem.amount}

                            
                          </TableCell>
                          <TableCell scope="row" className={classes.tablCell}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleApproveDeposit(depositItem.id)}
                            >
                              Approve
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  })}
</Grid>










    <Grid container spacing={4}  justifyContent="center" sx={{ marginTop: 3}}>
    <Grid item xs={12} sm={12} md={6} >
      <Card>
        <CardContent>
          <HighchartsReact highcharts={Highcharts} options={lineChartOptions} />
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={12} md={6}>
      <Card>
        <CardContent>
          <HighchartsReact highcharts={Highcharts} options={barChartOptions} />
        </CardContent>
      </Card>
    </Grid>
  </Grid>

    <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 1 }}>
  {/* Card 1 */}
  <Grid item xs={12} sm={4} md={4}>
    <Card elevation={3} >
      <CardContent>
        <Grid container direction="column" alignItems="center">
          {/* <FaClipboardList size={40} color="#4CAF50" /> */}
          <Typography variant="h5" mt={2}>
            Total Deposit
          </Typography>
          <Typography
            variant="h3"
            sx={{ marginTop: 2, fontWeight: "bold", color: "#4CAF50" }}
          >
        ${totalBankBalance.toFixed(2) || '0'}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>

  <Grid item xs={12} sm={4} md={4}>
    <Card elevation={3} >
      <CardContent>
        <Grid container direction="column" alignItems="center">
          {/* <FaClipboardList size={40} color="#4CAF50" /> */}
          <Typography variant="h5" mt={2}>
            Total Expense
          </Typography>
          <Typography
            variant="h3"
            sx={{ marginTop: 2, fontWeight: "bold", color: "#4CAF50" }}
          >
        ${Number(calculatePaidExpenses()).toFixed(2) || '0.00'}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>


  <Grid item xs={12} sm={4} md={4}>
    <Card elevation={3} >
      <CardContent>
        <Grid container direction="column" alignItems="center">
          {/* <FaClipboardList size={40} color="#4CAF50" /> */}
          <Typography variant="h5" mt={2}>
            Bank Balance
          </Typography>
          <Typography
            variant="h3"
            sx={{ marginTop: 2, fontWeight: "bold", color: "#4CAF50" }}
          >
   ${formatCurrency(totalBankBalance - calculatePaidExpenses())}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>



</Grid>


<Grid container spacing={4} justifyContent="center" sx={{ marginTop: 1 }}>
  {team.map((person, index) => {

    const personDeposits = deposit.filter(d => d.user_id === person.id ); 
    
    const personTotalDeposit = personDeposits.filter((d) => d.is_approved)
    .reduce((acc, curr) => acc + Number(curr.amount), 0);

    return (
      <Grid item xs={12} sm={6} md={6} key={index}>
        <Card elevation={3} sx={{ height: '350px', display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: 1 }}>
            <Grid container alignItems="center" mt={1} justifyContent="center" spacing={2}>
              <Grid item>
                <Typography variant="body2" align="center" sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                  {person.driverFirstName} {person.driverLastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', color: "#4CAF50" }}>
                  ${personTotalDeposit.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                size="small" 
                variant="contained"
                color="primary"
                onClick={() => handleDepositClick(person.id)}
              >
                Deposit
              </Button>
            </Box>
            <Box
              mt={3}
              sx={{ maxHeight: '200px', overflowY: 'auto' }}
              className={classes.thinScrollbar}
            >
              <Table className={classes.table}>
                <TableHead className={classes.tableHeaderRow}>
                  <TableRow>
                    <TableCell className={classes.tablheader}>Date</TableCell>
                    <TableCell className={classes.tablheader}>Amount</TableCell>
                    <TableCell className={classes.tablheader}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {personDeposits.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center" className={classes.tablCell}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    personDeposits
                    .sort((a, b) => {
                      const dateA = new Date(a.date).getTime();
                      const dateB = new Date(b.date).getTime();
                  
                      // First sort by date (descending)
                      if (dateB !== dateA) {
                        return dateB - dateA;
                      }
                  
                      // If the dates are the same, sort by ID (ascending)
                      return b.id - a.id;
                    }).
                    map((depositItem, idx) => (
                      <TableRow key={idx}>
                        <TableCell scope="row" className={classes.tablCell}>
                          {new Date(depositItem.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          ${depositItem.amount}
                          {depositItem.remarks ? (
        <MuiTooltip
          title={depositItem.remarks}
          arrow
          open={tooltipOpen[depositItem.id] || false}
          onOpen={() => handleTooltipOpen(depositItem.id)}
          onClose={() => handleTooltipClose(depositItem.id)}
          disableInteractive
        >
          <InfoIcon
            sx={{
              color: '#2196F3',
              cursor: 'pointer',
              fontSize: '1.1rem',
              marginLeft: '8px',
              verticalAlign: 'middle',
            }} 
            onClick={()=>handleMobileTooltipToggle(depositItem.id)} // Mobile: tap to toggle
            onTouchStart={()=>handleMobileTooltipToggle(depositItem.id)}  
          />
        </MuiTooltip>
      ) : null}
                        </TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                          {depositItem.is_approved ? (
                            <CheckCircleIcon sx={{ color: 'green', backgroundColor: '#d4edda', borderRadius: '50%' }} />
                          ) : (
                            <CheckCircleIcon sx={{ color: 'red', backgroundColor: '#f8d7da', borderRadius: '50%' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  })}
</Grid>







    <Grid container spacing={4}  sx={{ marginTop: 2 }}>
    <Grid item xs={12} sm={12} md={12}>
    <Card className={classes.card}>



        <Grid
          container
          justifyContent={"space-between"}
          spacing={5}
          sx={{ borderBottom: 2, padding: 2 }}
          className={classes.titleGrid}
        >
          <Grid item lg={4} md={4} sm={4} xs={4} pt={2} alignContent={"center"}>
            <Typography className={classes.cardTitle}>Expense</Typography>
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={8}
            xs={8}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              sx={{ display: "flex" }}
            >

              <Grid item>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CiSearch className={classes.searchWidgetIcon} />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.formControlList,
                    },
                  }}
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Expense Name .."
                  sx={{ width: "100%" }}
                  size="small" />
              </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.addButton}
                              onClick={handleAddProductClick}
                            >
                              Add Expense
                            </Button>
              
                          </Grid>

            </Grid>
          </Grid>
        </Grid>



        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card sx={{ padding: 3 }}>
          <TableContainer
      sx={{
        maxHeight: 450, // Fixed height for the table
        overflowY: 'auto', // Enables vertical scrolling
      }}
      className={classes.thinScrollbar} 
      >
              <Table className={classes.table}>
                <TableHead className={classes.tableHeaderRow}>
                  <TableRow>
                    <TableCell className={classes.tablheader}> <strong>Date</strong></TableCell>
                    <TableCell className={classes.tablheader}> <strong>Details</strong></TableCell>
                    <TableCell className={classes.tablheader}><strong>Category</strong></TableCell>
                    <TableCell className={classes.tablheader}> <strong>Amount</strong></TableCell>
                    <TableCell className={classes.tablheader}><strong>Hst</strong></TableCell>
                    <TableCell className={classes.tablheader}><strong>Total</strong></TableCell>
                    <TableCell className={classes.tablheader}><strong>Status</strong></TableCell>
                   
                    {/* <TableCell className={classes.tablheader}><strong>Invoice File</strong></TableCell> */}
                    <TableCell className={classes.tablheader} sx={{ textAlign: "right" }}>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredExpenses.length > 0 ? (
                 filteredExpenses
                 .sort((a, b) => {
                  const dateA = new Date(a.date).getTime();
                  const dateB = new Date(b.date).getTime();
              
                  // First sort by date (descending)
                  if (dateB !== dateA) {
                    return dateB - dateA;
                  }
              
                  // If the dates are the same, sort by ID (ascending)
                  return b.id - a.id;
                })
                   .map((expense) => (
                      <TableRow key={expense.id}>
                        <TableCell scope="row" className={classes.tablCell}>{expense.date}</TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
                        <Box display="flex" alignItems="center">
    {/* Details text */}
    <span>{expense.description}</span>
                            {expense.file && expense.file.length > 0 && (
      <Box display="flex" marginLeft={1}>
        {parseFiles(expense.file).map((file, index) => (
          <Button
            key={index}
            variant="text"
            className={classes.viewInvoiceButton}
            size="small"
            onClick={() => handleOpenModal(file)}
            sx={{
              minWidth: "auto",
              padding: 0,
              marginRight: 1, // Add space between icons
            }}
          >
            <DescriptionIcon style={{ fontSize: 16 }} />
          </Button>
        ))}
        
                              </Box>
                            )}
                           </Box>
                        </TableCell>



                        <TableCell scope="row" className={classes.tablCell}> {categoryMap[expense.category] || "Unknown"}</TableCell>
                        <TableCell scope="row" className={classes.tablCell}>{expense.amount}</TableCell>
                        <TableCell scope="row" className={classes.tablCell}>{expense.hst}</TableCell>
                        <TableCell scope="row" className={classes.tablCell}>{expense.total}</TableCell>
                        <TableCell scope="row" className={classes.tablCell}>
  <Box display="flex" alignItems="center">
    {/* <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: String(expense.status) === 'Paid' ? 'green' : 'red', // Ensure expense.status is a string
        marginRight: 1, // Space between dot and text
      }}
    /> */}
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        backgroundColor: String(expense.status) === 'Paid' ? 'lightgreen' : '#f8d7da', // Conditional background color
        padding: '2px 8px', // Padding to make the background visible
        borderRadius: 2, // Optional: to give a rounded corner to the background
      }}
    >
      {expense.status}
    </Typography>
  </Box>
</TableCell>



                        <TableCell scope="row" className={classes.tablCell}>
  <Grid
                          container
                          spacing={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                        {/* <Box display="flex" alignItems="center" justifyContent="space-between"> */}

    {/* {expense.before_images && expense.before_images.length > 0 && (
      <Box display="flex" marginLeft={1}>
        {parseFiles(expense.before_images).map((before_image, index) => (
          <Button
            key={index}
            variant="text"
            className={classes.viewInvoiceButton}
            size="small"
            onClick={() => handleOpenModal(before_image)}
            sx={{
              minWidth: "auto",
              padding: 0,
              marginRight: 1,
            }}
          >
            <DescriptionIcon style={{ fontSize: 20 }} />
          </Button>
        ))}
      </Box>
    )}

  
    {expense.after_images && expense.after_images.length > 0 && (
      <Box display="flex" marginLeft={1}>
        {parseFiles(expense.after_images).map((after_image, index) => (
          <Button
            key={index}
            variant="text"
            className={classes.viewInvoiceButton}
            size="small"
            onClick={() => handleOpenModal(after_image)}
            sx={{
              minWidth: "auto",
              padding: 0,
              marginRight: 1,
            }}
          >
            <DescriptionIcon style={{ fontSize: 20 }} />
          </Button>
        ))}
      </Box>
    )} */}

<Dialog open={openModalView} onClose={() => setOpenModalView(false)} maxWidth="lg" fullWidth>
  <DialogTitle>
    View Images
    <IconButton
      edge="end"
      color="inherit"
      onClick={() => setOpenModalView(false)}
      aria-label="close"
      sx={{ position: 'absolute', right: 8, top: 8 }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>

  <DialogContent>
  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
      {/* Left side for Before Images */}
      <Box flex={1} marginRight={{ md: 2 }}>
        <h3>Before Images</h3>
        {beforeImages.length > 0 ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {beforeImages.map((before_image, index) => {
                const imageUrl = `${LARAVEL_API_URL}/${before_image}`;
                //console.log(`Image URL: ${imageUrl}`);  // Log the image URL
                return (
                  <Box key={index} padding={1} borderRadius={4}>
              

{imageUrl.endsWith('.pdf') ? (
          <iframe
          src={imageUrl || ''} 
            style={{
              width: '100%',
              height: '500px',
            }}
            title="PDF Viewer"
          />
        ) : (
          <img
           src={imageUrl  || ''}
            alt="File Content"
            style={{
              width: '100%',
              height: 'auto', // Adjust height dynamically based on image aspect ratio
              maxHeight: '500px',
              objectFit: 'contain', // Ensures the image fits within the available space
            }}
          />
        )}

                </Box>
                );
})}
          </Box>
        ) : (
          <p>No before images available.</p>
        )}
      </Box>

      {/* Right side for After Images */}
      <Box flex={1} marginLeft={{ md: 2 }}>
        <h3>After Images</h3>
        {afterImages.length > 0 ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {afterImages.map((after_image, index) => {
                const imageUrl = `${LARAVEL_API_URL}/${after_image}`;
                //console.log(`Image After URL: ${imageUrl}`);  // Log the image URL
                return (
                  <Box key={index} padding={1} borderRadius={4}>
             
{imageUrl.endsWith('.pdf') ? (
          <iframe
          src={imageUrl || ''} 
            style={{
              width: '100%',
              height: '500px',
            }}
            title="PDF Viewer"
          />
        ) : (
          <img
           src={imageUrl  || ''}
            alt="File Content"
            style={{
              width: '100%',
              height: 'auto', // Adjust height dynamically based on content
              maxHeight: '500px', 
              objectFit: 'contain', // Ensures the image fits within the available space
            }}
          />
        )}

                </Box>
                );
})}
          </Box>
        ) : (
          <p>No after images available.</p>
        )}
      </Box>
    </Box>
  </DialogContent>
</Dialog>
<Grid item>


{(
  (Array.isArray(expense.before_images) && expense.before_images.length > 0) ||
  (Array.isArray(expense.after_images) && expense.after_images.length > 0) ||
  (expense.before_images && typeof expense.before_images === 'string' && JSON.parse(expense.before_images).length > 0) ||
  (expense.after_images && typeof expense.after_images === 'string' && JSON.parse(expense.after_images).length > 0)
) && (
<Button
  variant="contained"
  className={classes.viewButtonList}
  onClick={() => {
    // Ensure before_images is properly parsed and wrapped in an array if necessary
    let beforeImages = Array.isArray(expense.before_images)
      ? expense.before_images
      : expense.before_images
      ? JSON.parse(expense.before_images) // Parse if it's a stringified array
      : []; // Default to empty array if undefined or not valid
    
      let afterImages = Array.isArray(expense.after_images)
      ? expense.after_images
      : expense.after_images
      ? JSON.parse(expense.after_images) // Parse if it's a stringified array
      : []; // D
    //console.log("After Images:", afterImages);  // Log the parsed before images array
    
    // Handle the images by calling handleViewImages
    handleViewImages(beforeImages, afterImages);
  }}
 
>
  <VisibilitySharpIcon className={classes.viewListIcon} />
</Button>
)}



                            </Grid>
                            <Grid item>
    <Button
      variant="contained"
      className={classes.editButtonList}
      onClick={() => handleEditClick(expense.id)}
      sx={{
        marginLeft: "auto", // Pushes the edit button to the right
      }}
    >
      <EditSharpIcon className={classes.editListIcon} style={{ fontSize: 20 }} />
    </Button>
    </Grid>
    </Grid>
    
  {/* </Box> */}
                            {/* <Grid item>
                              <Button
                                variant="contained"
                                className={classes.deleteButtonList}
                                onClick={() => handleDeleteClick(expense.id)}
                              >
                                <DeleteOutlineSharpIcon
                                  className={classes.deleteButtonList} />
                              </Button>
                            </Grid> */}
                          
                        </TableCell>
                      </TableRow>

                    ))
                  ) : (

                    <TableRow>

                      <TableCell colSpan={7} align="center">
                        No Data Available
                      </TableCell>

                    </TableRow>

                  )}




                  <TableRow>

                    <TableCell colSpan={3} align="right">
                      <strong>Total </strong>
                    </TableCell>

                    <TableCell>{totalAmount.toFixed(2)}</TableCell>
                    {/* <TableCell> */}
                    {/* <strong>Total HST:</strong> */}
                    {/* </TableCell> */}
                    <TableCell>{totalHst.toFixed(2)}</TableCell>
                    <TableCell colSpan={2}>
                      {/* <strong>Total Amount (With HST):</strong>  */}
                      {totalAmountWithTax.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>

              </Table>

              {/* Modal Dialog */}
              <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
                <DialogTitle>View File</DialogTitle>
                <DialogContent>

                {isPdf ? (
          <iframe
            src={fileUrl || ''}
            style={{
              width: '100%',
              height: '500px',
            }}
            title="PDF Viewer"
          />
        ) : (
          <img
            src={fileUrl || ''}
            alt="File Content"
            style={{
              width: '100%',
              height: '500px',
              objectFit: 'contain', // Ensures the image fits within the available space
            }}
          />
        )}
               
                {/* <iframe
                    src={fileUrl || ''}
                    width="100%"
                    height="500px"
                    title="File Viewer"
                    frameBorder="0" /> */}
             
                 
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal} color="primary">Close</Button>
                </DialogActions>
              </Dialog>


              <Grid
                spacing={5}
                p={"18px"}
                py={4}
                justifyContent={"end"}
                container
                textAlign={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Grid
                  sx={{
                    textAlign: "end",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >

                  <Pagination
                    count={Math.ceil(filteredExpenses.length / rowsPerPage)}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage} />




                </Grid>

              </Grid>




            </TableContainer>
          


          </Card>
        </Grid>

      </Card>
      </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 1 }}>
      {Object.entries(categoryData).map(([categoryName, totalAmount]) => {
        const pieData = calculatePieData(categoryName); // Get pie chart data

        return (
          <Grid item xs={12} sm={4} md={4} key={categoryName}>
            <Card elevation={3} sx={{ height: '510px', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
                <Grid container direction="column" alignItems="center">
                  {/* Category Name */}
                 
                  <Typography variant="body2" mt={2} align="center" sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                  {categoryName}</Typography>


                 <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px' }}>
          {/* Left: Pie Chart */}
          <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '150px', height: '150px' }}>
            <Pie
  data={calculatePieData(categoryName)}
  options={{
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const { dataIndex, dataset } = tooltipItem;
            const totalValue = dataset.data.reduce((a, b) => a + b, 0); // Sum of all values in the dataset
            const value = dataset.data[dataIndex] as number;

            // Calculate the percentage for the category slice relative to totalExpense
            let percentage = "0.00";
            if (dataIndex === 0) {
              percentage = ((value / overallExpense) * 100).toFixed(2); // Percentage based on overallExpense
            }

            if (dataIndex === 0) {
              // Show category-specific percentage in tooltip
              return `${percentage}%`; // Display category percentage
            }
            // Show value for overall expense in tooltip
            return `${value.toFixed(2)}`;
          },
        },
      },
      legend: {
        display: true,
        labels: {
          color: '#000',
        },
      },
    },
  }}
/>

            </Box>
          </Grid>

          {/* Right: Percentage Display */}
          <Grid item md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" >
                Total  {expenses.filter((expense) => categoryMap[expense.category] === categoryName).length}
              </Typography>
              <Typography variant="h3" sx={{ fontSize: '1.2rem'}}>
                {((categoryData[categoryName] / overallExpense ) * 100).toFixed(2)}%
          
           
              </Typography>
             
            </Box>
          </Grid>
        </Grid>

                  {/* Scrollable Expenses Section */}
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      maxHeight: '200px', // Ensure it is scrollable
                      width: '100%',
                      marginTop: 2, // Ensure space between the pie chart and the content below
                    }}
                    className={classes.thinScrollbar}
                  >
                    <Grid container direction="column" spacing={2}>
                      {expenses
                        .filter((expense) => categoryMap[expense.category] === categoryName)
                        .map((expense, index) => (
                          <Grid item key={index} container justifyContent="space-between">
                            {/* Left: Description */}
                            <Grid item xs={8}>
                              <Typography variant="body2">
                                {expense.date} <br />
                                {expense.description}
                              </Typography>
                            </Grid>

                            {/* Right: Total */}
                            <Grid item xs={4} textAlign="right">
                              <Typography variant="body2">
                                ${expense.total}
                              </Typography>
                            </Grid>

                            {index < expenses.length - 1 && (
                              <Grid item xs={12}>
                                <Box sx={{ borderBottom: '1px solid #ccc', my: 1 }} />
                              </Grid>
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </Box>

                  {/* Bottom: Total Expense and Total HST */}
                  <Grid container justifyContent="space-between" sx={{ padding: '16px', position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <Grid item xs={6}>
            <Typography variant="h6">Total Expense:</Typography>
            <Typography variant="body2" sx={{ fontSize: "1.00rem" }}>
              ${categoryData[categoryName]?.toFixed(2) || "0.00"}
            </Typography>
          </Grid>

          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Total HST:</Typography>
            <Typography variant="body2" sx={{ fontSize: "1.00rem" }}>
              {/* ${categoryHST[categoryName] || "0.00"} */}
              ${((categoryHST[categoryName] ?? 0) as number).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>











      </>




       

  );
};

export default Index;
