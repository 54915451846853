import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";   
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStyles } from "../../../theme/appStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifier } from "../../../Core/Notifier";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const DepositForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { showErrorMessage, showMessage } = useNotifier();
  const { state } = useLocation();
  const userId = state?.userId;
   const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      date: new Date().toISOString().split("T")[0],
      amount: "",
      remarks:"",
    },
    validationSchema: yup.object({
      date: yup.date().required("Date is required"),
      amount: yup
      .number()
      .required("Deposit Amount is required")
      .min(1, "Deposit Amount must be at least 1"),
     
    }),
    onSubmit: async (values) => {
      setLoading(true);
     
        try {
          const formData = new FormData();
          formData.append("amount", values.amount);
          formData.append("user_id", userId); 
          formData.append("date", values.date);
          formData.append("remarks", values.remarks);
          const response = await axios.post(`${API_URL}/save-deposit`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
       
            showMessage("Amount deposited successfully!");
            formik.resetForm();
         
        } catch (error) {
          showErrorMessage("An error occurred while saving the amount.");
        } finally {
          setLoading(false);
        }
      }
  });

  return (
    <div>
      <Grid container spacing={3}>
   <Grid item xs={12} md={3}>
          <Card>
            <Grid
              container
              justifyContent="space-between"
              sx={{ borderBottom: 2, padding: 2 }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pt={2}
                alignContent={"center"}
              >
                <Typography className={classes.cardTitle}>
                  Create Deposit
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

         <Grid item xs={12} md={9}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
             <Grid item xs={12}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                    {/* Category Name Field */}
  <Grid item xs={12} sm={7}>
              <Typography variant="subtitle1">Date</Typography>
              <TextField
                type="date"
                size="small"
                value={formik.values.date}
                onChange={formik.handleChange}
                name="date"
                fullWidth
              />
            </Grid>

               <Grid item xs={12} sm={7}>
                      <Typography variant="subtitle1">Deposit Amount</Typography>
                      <TextField
  placeholder="Enter deposit amount"
  size="small"
  type="number"
  {...formik.getFieldProps("amount")}
  error={formik.touched.amount && Boolean(formik.errors.amount)}
  helperText={formik.touched.amount && formik.errors.amount}
  fullWidth
/>

                    </Grid>

                  <Grid item xs={12} sm={7}>
                                  <Typography variant="subtitle1">Remarks</Typography>
                                  <TextField
                                    placeholder="Enter remarks"
                                    size="small"
                                    {...formik.getFieldProps("remarks")}
                                    multiline
                                    rows={4} 
                                    fullWidth
                                    
                                  />
                                </Grid>

                  </Grid>
                </Card>
              </Grid>

              {/* Submit and Cancel Buttons */}
             <Grid item xs={12} pt={3}>
                <Card>
                  <Grid container spacing={3} sx={{ padding: 3 }}>
                     <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        className={classes.addButton}
                        startIcon={<CloseIcon />}
                        // onClick={() => formik.resetForm()}
                        onClick={() =>navigate('/expense/list')}
                      >
                        Cancel
                      </Button>
                      <Box m={0.5}></Box>
                      <Button
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<SaveAltIcon />}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default DepositForm;
