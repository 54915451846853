import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  topbar: {
    top: 0,
    zIndex: 1005,
    // transition: 'all 0.3s ease-in-out',
    height: "100px",
    backgroundColor: theme.palette.background.default,
    // paddingLeft: "75px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
    margin: "0px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 15,
    padding: 0,
  },
  mobileTopbar: {
    top: 0,
    zIndex: 1005,
    // transition: 'all 0.3s ease-in-out',
    height: "100px",
    backgroundColor: theme.palette.background.default,
    margin: "0px",
  },
  topbarButton: {
    border: "none",
    borderRadius: "50%",
    backgroundColor: "transparent",
    transition: "all 0.3s ease-in-out",
    color: theme.palette.topbarButton.topbarItemColor,
    // padding: "0.5rem 0.5rem",
    position: "relative",
    "&.show, &.active, &:active, &:hover": {
      color: "#ff6c2f",
    },
  },
  topbarIcon: {
    width: "1.7rem",
    height: "1.7rem",
    borderRadius: "50%",
  },
  topbarImgButton: {
    border: "none",
    borderRadius: "50%",
    backgroundColor: "transparent",
    transition: "all 0.3s ease-in-out",
    color: theme.palette.topbarButton.topbarItemColor,
    padding: "0.5rem 0.5rem",
    position: "relative",
    "&.show, &.active, &:active, &:hover": {
      color: "#ff6c2f",
    },
  },
  
  topbarImgIcon: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
  },
  topbarIconProfile: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
  },
  topbarTittle: {
    color: "#5d7186",
    height: "1.7rem",
    borderRadius: "50%",
  },
  appSearch: {
    position: "relative",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formControl: {
    border: "none",
    paddingLeft: "40px",
    paddingRight: "15px",
    backgroundColor: theme.palette.topbarButton.topbarSearchBg,
    boxShadow: "none",
    height: "42px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formControlList: {
    border: "none",
    paddingLeft: "40px",
    paddingRight: "15px",
    backgroundColor: theme.palette.topbarButton.topbarSearchBg,
    boxShadow: "none",
    height: "34px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formControlListQuto: {
    border: "none",
    paddingLeft: "40px",
    paddingRight: "15px",
    backgroundColor: theme.palette.topbarButton.topbarSearchBg,
    boxShadow: "none",
    height: "34px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  searchWidgetIcon: {
    position: "absolute",
    zIndex: 10,
    fontSize: "18px",
    left: "11px",
    top: "50%",
    color: "#8486a7",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  searchWidgetIconClose: {
    right: "7px",
    left: "auto !important",
  },
  notchedOutline: {
    border: "none",
  },
  drawerHeader: {
    height: "100px",
    backgroundColor: theme.palette.background.default,
    paddingLeft: "75px",
    margin: "0px",
  },

  h1: {
    fontSize: "calc(1.35rem + 1.2vw)",
    fontWeight: 600,
    lineHeight: 1.1,
    color: theme.palette.heading.headingColor,
    "@media (min-width: 1200px)": {
      fontSize: "2.25rem",
    },
  },
  h2: {
    fontSize: "calc(1.3125rem + 0.75vw)",
    fontWeight: 600,
    lineHeight: 1.1,
    color: theme.palette.heading.headingColor,
    "@media (min-width: 1200px)": {
      fontSize: "1.875rem",
    },
  },
  h3: {
    fontSize: "calc(1.275rem + 0.3vw)",
    fontWeight: 600,
    lineHeight: 1.1,
    color: theme.palette.heading.headingColor,
    "@media (min-width: 1200px)": {
      fontSize: "1.5rem",
    },
  },
  h4: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "21px",
    color: theme.palette.heading.headingColor,
    textTransform: "uppercase",
  },
  h4Quoto: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "21px",
    color: theme.palette.heading.headingColor,
  },
  h5: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: 1.1,
    color: theme.palette.heading.headingColor,
  },
  h6: {
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: 1.1,
    color: theme.palette.heading.headingColor,
  },
  menuTitle: {
    cursor: "default",
    fontSize: "11px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#9097a7",
    opacity: 0.6,
    fontWeight: 600,
    padding: `${theme.spacing(1)} ${theme.spacing(1.25)}`,
    transition: "all 0.3s ease-in-out",
    height: "36px",
    margin: 0,
  },
  listItemIcomMainMenu: {
    margin: 0,
    width: 40,
    marginLeft: 0,
    color: theme.palette.text.primary,
    opacity: 0.6,
  },

  body1: {
    fontSize: "1rem",
    lineHeight: 1.5,
    // marginBottom: "1rem",
    textAlign: "center",
    margin: 0,
    textTransform: "uppercase",
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.43,
    marginBottom: "1rem",
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.57,
    marginBottom: "0.5rem",
  },
  subtitle2: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 1.57,
    marginBottom: "0.5rem",
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: 1.66,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: 2.66,
    textTransform: "uppercase",
  },

  navbarHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto",
  },

  searchIcon: {
    position: "absolute",
    zIndex: 10,
    fontSize: 18,
    left: 11,
    top: "50%",
    color: "#8486a7",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: theme.spacing(1),
    backgroundColor: "var(--bs-topbar-search-bg)",
    border: "none",
    boxShadow: "none",
    height: 42,
    width: "100%",
  },
  buttonToggleMenu: {
    color: "var(--bs-topbar-item-color)",
    backgroundColor: "transparent",
    borderRadius: 0,
    "&:hover": {
      color: "#ff6c2f",
    },
  },
  topbarItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "var(--bs-topbar-height)",
  },
  card: {
    backgroundColor: theme.palette.cardAdd.bgColor,
    p: "24px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  quotesCard: {
    backgroundColor: theme.palette.cardAdd.bgColor,
    [theme.breakpoints.down("md")]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
    marginLeft: "98px",
    marginRight: "98px",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "none",
  },
  quotesCardInvoice: {
    backgroundColor: theme.palette.cardAdd.bgColor,
    [theme.breakpoints.down("md")]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
    marginLeft: "98px",
    marginRight: "98px",
    padding: "12px",
    borderRadius: "8px",
    boxShadow: "none",
  },
  Dialog: {
    backgroundColor: theme.palette.topbarButton.topbarBg,
    borderRadius: "8px",
    padding: "24px",
  },
  cardTitle: {
    fontSize: "16px",
    fontWeight: 600,
    margin: 0,
    color: theme.palette.cardAdd.headingColor,
  },
  cardTitleContainer: {
    borderBottom: 1,
    padding: 2,
    backgroundColor: theme.palette.cardAdd.bgColor,
  },
  stepperCard: {
    boxShadow: "none",
    backgroundColor: theme.palette.cardAdd.bgColor,
    borderRadius: "8px",
    fontSize: "16px",
    p: 5,
    fontWeight: 400,
  },
  stepperCardGrid: {
    boxShadow: "none",
    borderRadius: "8px",
    fontSize: "16px",
    p: 5,
    fontWeight: 400,
    display: "flex",
    flexDirection: "column",
  },
  addButtonList: {
    borderBottom: 1,
    padding: 2,
    backgroundColor: theme.palette.cardAdd.bgColor,
  },
  addButton: {
    paddingX: "1rem",
    paddingY: "0.5rem",
    fontFamily: "inherit",
    textTransform: "none",
    fontWeight: 600,
    lineHeight: 1.5,
    // backgroundColor: "#ff6c2f",
    borderRadius: "0.75rem",
    // boxShadow: "0px 2px 6px 0px",
    // color: '#aab8c5',
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    "&:hover": {
      borderColor: "transparent",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.15rem rgba(var(--bs-btn-focus-shadow-rgb), .5)",
    },
    "&:disabled": {
      opacity: 0.65,
    },
  },
  
  refundButton: {
    paddingX: "1rem",
    paddingY: "0.5rem",
    fontFamily: "inherit",
    textTransform: "none",
    fontWeight: 600,
    lineHeight: 1.5,
    border: "1px solid",
    // backgroundColor: "#ff6c2f",
    borderRadius: "0.75rem",
    // boxShadow: "0px 2px 6px 0px",
    color: '#aab8c5',
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    "&:hover": {
      borderColor: "transparent",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.15rem rgba(var(--bs-btn-focus-shadow-rgb), .5)",
    },
    "&:disabled": {
      opacity: 0.65,
    },
  },
  textField: {
    flexGrow: 1,
    width: "100%",
    fontSize: 14,
  },
  textFieldQuote: {
    flexGrow: 1,
    width: "100%",
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },
  QuoteGridContainer: {
    // borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
    paddingTop: "8px",
  },
  gridBordrBottomColor: {
    borderBottom: 1,
    padding: 2,
    borderColor: theme.palette.divider,
  },
  table: {
    width: "100%",
    marginBottom: "1.5rem",
    verticalAlign: "top",
    // borderColor: `1px solid ${theme.palette.cardAdd.borderColor}`,
    "& > :not(caption) > * > *": {
      padding: "0.85rem 0.85rem",
    },
  },

  tableHeaderRow: {
    background: theme.palette.cardAdd.rowHeaderColor,
    // borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },
  tablCell: {
    borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },

  tablheader: {
    borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
    borderTop: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },

  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.cardAdd.tablehover,
      borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },
  editButton: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "0.75rem",
    opacity: 0.65,
    color: "#ff6c2f",
    border: "none",
    padding: 1,
    minWidth: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ff6c2f36",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ff6c2f",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  },

  deleteButton: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "0.75rem",
    opacity: 0.65,
    color: "#ef5f5f",
    border: "none",
    padding: 1,
    minWidth: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ef5f5f54",
    "&:hover": {
      backgroundColor: "#ef5f5f",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  },
  viewButton: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "0.75rem",
    opacity: 0.65,
    color: "#323a46",
    // color: "#eef2f7",
    border: "none",
    padding: 1,
    minWidth: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#99c9cf6e",
    "&:hover": {
      backgroundColor: "#323a46",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  },
  editButtonList: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "8px",
    opacity: 0.65,
    color: "#ff6c2f1a",
    border: "none",
    padding: 1,
    minWidth: 44,
    height: 33,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ff6c2f36",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ff6c2f",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
    boxShadow: "none",
  },
  deleteButtonList: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "8px",
    opacity: 0.65,
    color: "#ef5f5f",
    border: "none",
    padding: 1,
    minWidth: 44,
    height: 33,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ef5f5f1a",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef5f5f",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
    boxShadow: "none",
  },
  viewButtonList: {
    px: "1rem",
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "8px",
    opacity: 0.65,
    color: "#323a46",
    border: "none",
    padding: 1,
    minWidth: 44,
    height: 33,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#99c9cf6e",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#323a46",
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
    boxShadow: "none",
  },
  statusButtonList: {
    fontWeight: 500,
    borderRadius: "8px",
    minWidth: 44,
    height: 33,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    textTransform: "inherit",
  },
  statusButtonListWorkOrderList: {
    fontWeight: 500,
    borderRadius: "16px",
    minWidth: 44,
    height: 25,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    textTransform: "inherit",
  },
  editListIcon: {
    // fontSize: "0.95rem",
    color: "#ff6c2f",
  },
  deleteListIcon: {
    // fontSize: "0.95rem",
    color: "#ef5f5f",
  },
  viewListIcon: {
    // fontSize: "0.95rem",
    color: "#323a46",
  },
  imgBox: {
    border: "2px dashed",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "6px",
    cursor: "pointer",
    minHeight: "150px",
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  SelectImageBox: {
    width: "100%",
    height: "auto",
    maxWidth: "100px",
    maxHeight: "100px",
    marginRight: "auto",
    objectFit: "cover",
  },
  titleGrid: {
    borderColor: theme.palette.cardAdd.borderColor,
  },
  titleGridInvoice: {
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "12px",
    backgroundColor: "#dcf4f3",
    // padding: "2px"
  },
  printerBox: {
    border: "2px dashed",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "6px",
    minHeight: "150px",
    padding: "5px",
  },
  colorBox: {
    border: "2px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "6px",
    minHeight: "70px",
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  barCodeBox: {
    border: "2px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "6px",
    minHeight: "70px",
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  discriptionTextField: {
    flexGrow: 1,
    width: "100%",
    fontSize: 14,
    backgroundColor: theme.palette.cardAdd.textDescription,
  },
  customerDetails: {
    fontWeight: 800,
  },
  customerDiscription: {
    backgroundColor: theme.palette.cardAdd.textDescription,
  },
  productBox: {
    border: "2px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
    borderRadius: "6px",
    height: "350px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productViewPageTableHeaderCell: {
    fontSize: "16px",
    fontWeight: 600,
    borderBottom: 4,
    borderColor: theme.palette.cardAdd.borderColor,
  },
  productBarCodeBox: {
    border: "2px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "6px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameTableList: {
    color: theme.palette.table.nameHeadingColor,
    fontSize: "15px",
    fontWeight: 500,
  },
  SelectImageTable: {
    width: "50px",
    height: "50px",
    border: "none",
    position: "inherit",
  },
  SelectImageQuoteTable: {
    width: "80px",
    height: "80px",
    borderColor: theme.palette.cardAdd.borderColor,
    // border: "none",
    border: "1px solid",
    position: "inherit",
    borderRadius: "8px",
  },
  SelectImageTableBox: {
    width: "56px",
    height: "56px",
    p: "5px",
    border: "1px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  SelectImageTableBoxProject: {
    width: "65px",
    height: "38px",
    p: "5px",
    border: "1px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "8px",
    display: "flex",
    alignItems:"flex-start",
    justifyContent: "left",
  },
  SelectImagInvoiceBox: {
    width: "80px",
    height: "80px",
    p: "5px",
    border: "1px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  SelectImageInvoice: {
    width: "70px",
    height: "70px",
    border: "none",
    position: "inherit",
  },
  SelectImageQuoteTableBox: {
    width: "80px",
    height: "80px",
    border: "1px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  SelectImageQuoteTableGrid: {
    padding: "8px",
    border: "1px solid",
    borderColor: theme.palette.cardAdd.borderColor,
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  dropdownPaper: {
    maxHeight: "200px",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  quoteImg: {
    width: "100%",
    height:"100%",
    // height: "150px",
    padding: "12px",
    border: 1,
    borderColor: "#ff6c2f",
    borderStyle: "dashed",
    borderRadius: "12px",
    display: "block",
    cursor: "pointer",
  },
  invoiceImg: {
    width: "100%",
    height:"100%",
    // height: "50px",
    padding: "12px",
    borderRadius: "12px",
    display: "block",
    cursor: "pointer",
  },
  tablheaderCreate: {
    borderBottom: `1px solid ${theme.palette.cardAdd.borderColor}`,
  },
  quoteImgSelctMenu: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    objectFit: "cover",
    borderRadius: "4px",
  },
  formControlListQutoWithoutSearch: {
    border: "none",
    backgroundColor: theme.palette.topbarButton.topbarSearchBg,
    boxShadow: "none",
    height: "34px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  profileBottomSetUp: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    background: `linear-gradient(to bottom, ${theme.palette.text.primary} 42%, #FFFFFF 18%, #FFFFFF 50%)`,
  },
  profileBottomSetUpSmall: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    background: `linear-gradient(to bottom, #FFFFFF 42%, #FFFFFF 18%, #FFFFFF 50%)`,
  },
  profileBottomDwon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100px',
    height: '100px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    // border: `5px solid #FFFFFF`,
    overflow: 'hidden',
    // marginTop: '-25px', 
    zIndex: 1,
  },
  profileImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  profileDetails: {
    marginTop: '10px', 
    color: theme.palette.text.secondary,
    textAlign: 'center', 
  },
  profileDetailItem: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.cardAdd.headingColor,
    fontWeight: 600,
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px', 
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px', 
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '16px',
  },
  buttonItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLabel: {
    marginTop: '8px',
  },
  editButtonProfile: {
    px: "1rem",  
    py: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    borderRadius: "0.75rem",
    color: "#FFFFFF",
    border: "none",
    padding: 1,
    minWidth: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main, 
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
    "&:active": {
      backgroundColor: "#ff6c2f", 
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  },
  editButtonProfileBox: {
    y: "0.5rem",
    fontSize: "0.875rem",
    width: 60,
    height: 60,
    fontWeight: 400,
    lineHeight: 1.5,
    border: "none",
    padding: 1,
    color: "#ff6c2f", 
    backgroundColor: "#ffccbc", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.75rem",
  },
  profileBottomDwonProfile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100px',
    height: '100px',
    borderRadius: '8px',
    backgroundColor: "#000000",
    // border: `5px solid #FFFFFF`,
    overflow: 'hidden',
    // marginTop: '-25px', 
    zIndex: 1,
  },
  profileTypograpy: {
    fontSize: "65px",
    color: "#FFFFFF",
    fontWeight: 600
  },
  showMoreLink: {
    color: "#1976d2",  
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",  
    },
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    // add any other styling you need here
  },
  viewInvoiceButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  thinScrollbar: {
    scrollbarWidth: 'thin', // Firefox
    // scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`, // Firefox
    '&::-webkit-scrollbar': {
      width: '8px', // width of the scrollbar for Webkit browsers (Chrome, Safari, etc.)
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '10px',
    },
  },

  container: {
    width: '100%', // Full width of the parent
    height: '500px', // Define a fixed or dynamic height
    display: 'flex', // Flexbox for alignment
    justifyContent: 'center', // Horizontally center the iframe
    alignItems: 'center', // Vertically center the iframe
    overflow: 'hidden', // Prevent scrolling of the container
    backgroundColor: '#f4f4f4', // Optional: Add a background for contrast
  },
  fileViewer: {
    width: '100%', // Full width of the container
    height: '100%', // Full height of the container
    border: 'none', // Remove any border around the iframe
    objectFit: 'contain', // Contain the content inside the iframe
    display: 'block', // Prevent any inline spacing issues
  },
  
}));



